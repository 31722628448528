<template>
  <div>
    <h1 :class="$style.pageTitle">設定</h1>
    <div class="row">
      <div class="col-lg-12">
        <div v-if="useAnalyze && !isFleet" :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>除外違反項目</h2>
            </div>
            <div class="card-body">
              <div v-if="excludeViolationsLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!excludeViolationsLoading">
                <a-checkbox-group v-model="excludeViolationCheckList" :class="$style.excViolationCheckList">
                  <div class="row">
                    <div class="col-lg-3" v-for="item in typeOptions" v-bind:key="item.value">
                      <a-checkbox :disabled="item.disabled" :value="item.value">{{item.label}}</a-checkbox>
                    </div>
                  </div>
                </a-checkbox-group>
              </div>
            </div>
          </section>
          <p class="text-center" v-if="!excludeViolationsLoading" :class="$style.buttonContainer">
            <a-button :loading="excludeViolationButtonLoading" @click="onSubmitExcludeViolations" :class="$style.filledBtn">
              更新
            </a-button>
          </p>
        </div>
        <div v-if="useAnnounce && !isFleet" :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>除外アナウンス項目</h2>
            </div>
            <div class="card-body">
              <div v-if="excludeAnnouncementLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!excludeAnnouncementLoading">
                <a-checkbox-group v-model="excludeAnnouncementCheckList" :class="$style.excViolationCheckList">
                  <div class="row">
                    <div class="col-lg-2" v-for="item in announceOptions" v-bind:key="item.value">
                      <a-checkbox :disabled="item.disabled" :value="item.value">{{item.label}}</a-checkbox>
                    </div>
                  </div>
                </a-checkbox-group>
              </div>
            </div>
          </section>
          <p class="text-center" v-if="!excludeAnnouncementLoading" :class="$style.buttonContainer">
            <a-button :loading="excludeAnnouncementButtonLoading" type="primary" @click="onSubmitExcludeAnnouncements" :class="$style.filledBtn">
              更新
            </a-button>
          </p>
        </div>
        <div v-if="useAnnounce && !isFleet" :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>アナウンス閾値設定</h2>
            </div>
            <div class="card-body">
              <div v-if="announcementThresholdLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!announcementThresholdLoading">
                <a-form-model
                  ref="announcementThresholdRuleForm"
                  :model="announcementThresholdForm"
                  :rules="announcementThresholdRules"
                  layout="inline"
                  :hideRequiredMark="true"
                >
                  <a-form-model-item ref="maximumSpeed" label="速度超過" prop="maximumSpeed">
                    <span :class="$style.requiredMark">必須</span>
                    <a-input-number v-model="announcementThresholdForm.maximumSpeed" :min="0" :max="100"/>
                  </a-form-model-item>
                  <a-form-model-item ref="maximumSpeedZone30" label="速度超過（ゾーン30）" prop="maximumSpeedZone30">
                    <span :class="$style.requiredMark">必須</span>
                    <a-input-number v-model="announcementThresholdForm.maximumSpeedZone30" :min="0" :max="100"/>
                  </a-form-model-item>
                  <a-form-model-item ref="temporaryStop" label="一時停止" prop="temporaryStop">
                    <span :class="$style.requiredMark">必須</span>
                    <a-input-number v-model="announcementThresholdForm.temporaryStop" :min="0" :max="100"/>
                  </a-form-model-item>
                  <a-form-model-item ref="railroadStop" label="踏切不停止" prop="railroadStop">
                    <span :class="$style.requiredMark">必須</span>
                    <a-input-number v-model="announcementThresholdForm.railroadStop" :min="0" :max="100"/>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </section>
          <p class="text-center" v-if="!announcementThresholdLoading" :class="$style.buttonContainer">
            <a-button :loading="announcementThresholdButtonLoading" type="primary" @click="onSubmitAnnouncementThreshold" :class="$style.filledBtn">
              更新
            </a-button>
          </p>
        </div>

        <div :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>アルコール濃度閾値設定</h2>
            </div>
            <div class="card-body">
              <div v-if="alcoholThresholdLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!alcoholThresholdLoading" :class="$style.notificationMailSettings">
                <a-form-model
                  ref="alcoholThresholdForm"
                  layout="horizontal"
                >
                  <a-form-model-item ref="alcoholThreshold" label="アルコール濃度閾値" prop="alcoholThreshold">
                    <a-input-number v-model="alcoholThreshold" :min="0" :max="100"/>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </section>
          <p class="text-center" v-if="!alcoholThresholdLoading" :class="$style.buttonContainer">
            <a-button :loading="alcoholThresholdButtonLoading" type="primary" @click="onSubmitalcoholThreshold" :class="$style.filledBtn">
              更新
            </a-button>
          </p>
        </div>

        <div :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>点呼記録簿にアルコール濃度の表示</h2>
            </div>
            <div class="card-body">
              <div v-if="alcoholMeasurementHideLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!alcoholMeasurementHideLoading" :class="$style.notificationMailSettings">
                <a-radio-group v-model="alcoholMeasurementHide">
                  <a-radio :value="0">表示する</a-radio>
                  <a-radio :value="1">表示しない</a-radio>
                </a-radio-group>
              </div>
            </div>
          </section>
          <p class="text-center" v-if="!alcoholMeasurementHideLoading" :class="$style.buttonContainer">
            <a-button :loading="alcoholMeasurementHideButtonLoading" type="primary" @click="onSubmitalcoholMeasurementHide" :class="$style.filledBtn">
              更新
            </a-button>
          </p>
        </div>

        <div :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>点呼記録簿の自由項目</h2>
            </div>
            <div class="card-body">
              <div v-if="freeItemAlcoholCheckLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!freeItemAlcoholCheckLoading">
                <a-list
                  item-layout="horizontal"
                  :data-source="freeItemAlcoholCheckList"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a slot="actions"><a-button @click="updateFreeItemAlcoholCheck(item.id, item.name, item.show_timing, item.edit_self_flag, item.priority, item.choice, item.required_item)">編集</a-button></a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p>{{ item.name }} <small>[自身での編集: {{ item.edit_self_flag === 0 ? '不許可' : '許可' }} / 優先度: {{ item.priority }} / 回答: {{ item.required_item === 0 ? '任意' : '必須' }} / 表示: {{ showTimingChoice[item.show_timing] }}]</small></p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </section>
          <p class="text-center" :class="$style.buttonContainer">
            <a-button :loading="freeItemAlcoholCheckButtonLoading" type="primary" @click="onMakeFreeItemAlcoholCheck" :class="$style.filledBtn">
              追加
            </a-button>
          </p>
        </div>

        <div :class="$style.cardContainer" v-if="plus">
          <section class="card">
            <div class="card-header">
              <h2>運行前点検項目</h2>
            </div>
            <div class="card-body">
              <div v-if="inspectionItemLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-else>
                <a-list
                  item-layout="horizontal"
                  :data-source="inspectionItemList"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a slot="actions">
                      <a-button @click="onUpdateInspectionItem(item)">編集</a-button>
                    </a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p>
                          {{ item.name }}
                          <small>
                            [カテゴリ: {{ item.category }}
                              / 回答: {{ item.required_item === 1 ? '必須' : '任意' }}
                              / 優先度: {{ item.priority }}
                              <template v-if="item.locked_item === 1">/ デフォルト項目]</template>
                              <template v-else>]</template>
                          </small>
                      </p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </section>
          <p class="text-center" :class="$style.buttonContainer">
            <a-button :loading="inspectionItemButtonLoading" type="primary" @click="onMakeInspectionItem" :class="$style.filledBtn">
              追加
            </a-button>
            <a-button
              v-if="!inspectionItemLoading && inspectionItemList.length === 0"
              :loading="inspectionItemButtonLoading"
              type="primary" @click="onMakeDefaultInspectionItem"
              :class="$style.filledBtn" class="ml-4"
            >
              デフォルトの項目を作る
            </a-button>
          </p>
        </div>

        <div :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>ユーザーの自由項目</h2>
            </div>
            <div class="card-body">
              <div v-if="freeItemUserLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!freeItemUserLoading">
                <a-list
                  item-layout="horizontal"
                  :data-source="freeItemUserList"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a slot="actions"><a-button @click="updateFreeItemUser(item.id, item.name, item.hide_drive_data_list, item.priority)">編集</a-button></a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p>{{ item.name }} <small>[運行: {{ item.hide_drive_data_list ? '非表示' : '表示' }} | 優先度: {{ item.priority }}]</small></p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </section>
          <p class="text-center" :class="$style.buttonContainer">
            <a-button :loading="freeItemUserButtonLoading" type="primary" @click="onMakeFreeItemUser" :class="$style.filledBtn">
              追加
            </a-button>
          </p>
        </div>

        <div :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>運転日報内の運転目的項目</h2>
            </div>
            <div class="card-body">
              <div v-if="drivingPurposeLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!drivingPurposeLoading">
                <a-list
                  item-layout="horizontal"
                  :data-source="drivingPurposeList"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a slot="actions"><a-button @click="updateDrivingPurpose(item.id, item.name)">編集</a-button></a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p>{{ item.name }}</p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </section>
          <p class="text-center" :class="$style.buttonContainer">
            <a-button :loading="drivingPurposeButtonLoading" type="primary" @click="onMakeDrivingPurpose" :class="$style.filledBtn">
              追加
            </a-button>
          </p>
        </div>

        <div v-if="useAnalyze && !isFleet" :class="$style.cardContainer">
          <section class="card" v-if="useAnalyze">
            <div class="card-header">
              <h2>通知メールアドレス</h2>
            </div>
            <div class="card-body">
              <div v-if="noticeEmailAddressLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!noticeEmailAddressLoading">
                <a-list
                  item-layout="horizontal"
                  :data-source="noticeEmailAddressList"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a slot="actions"><a-button @click="updateNoticeEmailAddress(item.id, item.email)">編集</a-button></a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p>{{ item.email }}</p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>

              </div>
            </div>
          </section>
          <p class="text-center" v-if="!noticeEmailAddressLoading" :class="$style.buttonContainer">
            <a-button :loading="excludeViolationButtonLoading" type="primary" @click="onMakeNoticeEmailAddress" :class="$style.filledBtn">
              追加
            </a-button>
          </p>
        </div>
        <div v-if="useAnalyze && !isFleet" :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>通知メール設定</h2>
            </div>
            <div class="card-body">
              <div v-if="noticeEmailSettingLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!noticeEmailSettingLoading" :class="$style.notificationMailSettings">
                <a-form-model
                  ref="noticeEmailSettingRuleForm"
                  :model="noticeEmailSettingForm"
                  :rules="noticeEmailSettingRules"
                  :hideRequiredMark="true"
                  layout="horizontal"
                >
                  <a-form-model-item ref="emailSendTime" label="送信時刻" prop="emailSendTime">
                    <span :class="$style.requiredMark">必須</span>
                    <a-time-picker v-model="emailSendTimeMoment"  format="HH:mm" :minute-step="15" @change="onChangeEmailSendTime" />
                  </a-form-model-item>
                  <a-form-model-item ref="numberOfRanking" label="ランキング件数" prop="numberOfRanking">
                    <span :class="$style.requiredMark">必須</span>
                    <a-input-number v-model="noticeEmailSettingForm.numberOfRanking" :min="0" :max="10000"/>
                    <span style="margin-left: 12px;color: #1F2437; font-size: 14px; line-height: 33px;">※すべてを出力する場合は0を入れてください。</span>
                  </a-form-model-item>
                  <h2 :class="$style.midTitle">メールに含めるランキング</h2>
                  <div :class="$style.mailRankingSettings">
                    <a-form-model-item ref="emailContentComplianceRate" label="遵守率" prop="emailContentComplianceRate" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                      <a-checkbox-group :default-value="emailContentComplianceRate" @change="onChangeEmailContentComplianceRate">
                        <div>
                          <div :class="$style.rankingItemContainer">
                            <div :class="$style.rankingItemTitle">全社ランキング</div>
                            <div>
                              <span :class="$style.itemLabel">ベスト</span>
                              <span><a-checkbox value="0">支店</a-checkbox></span>
                              <span><a-checkbox value="1">ユーザー</a-checkbox></span>
                              <span><a-checkbox value="2">車両</a-checkbox></span>
                            </div>
                            <div>
                              <span :class="$style.itemLabel">ワースト</span>
                              <span><a-checkbox value="3">支店</a-checkbox></span>
                              <span><a-checkbox value="4">ユーザー</a-checkbox></span>
                              <span><a-checkbox value="5">車両</a-checkbox></span>
                            </div>
                          </div>
                          <div :class="$style.rankingItemContainer">
                            <div :class="$style.rankingItemTitle">支店ランキング</div>
                            <div>
                              <span :class="$style.itemLabel">ベスト</span>
                              <span><a-checkbox value="6">ユーザー</a-checkbox></span>
                              <span><a-checkbox value="7">車両</a-checkbox></span>
                            </div>
                            <div>
                              <span :class="$style.itemLabel">ワースト</span>
                              <span><a-checkbox value="8">ユーザー</a-checkbox></span>
                              <span><a-checkbox value="9">車両</a-checkbox></span>
                            </div>
                          </div>
                        </div>
                      </a-checkbox-group>
                    </a-form-model-item>
                  <a-form-model-item ref="emailContentTimeRate" label="違反数 / 運転時間" prop="emailContentTimeRate" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-checkbox-group :default-value="emailContentTimeRate" @change="onChangeEmailContentTimeRate">
                      <div>
                        <div :class="$style.rankingItemContainer">
                          <div :class="$style.rankingItemTitle">全社ランキング</div>
                          <div>
                            <span :class="$style.itemLabel">ベスト</span>
                            <span><a-checkbox value="0">支店</a-checkbox></span>
                            <span><a-checkbox value="1">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="2">車両</a-checkbox></span>
                          </div>
                          <div>
                            <span :class="$style.itemLabel">ワースト</span>
                            <span><a-checkbox value="3">支店</a-checkbox></span>
                            <span><a-checkbox value="4">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="5">車両</a-checkbox></span>
                          </div>
                        </div>
                        <div :class="$style.rankingItemContainer">
                          <div :class="$style.rankingItemTitle">支店ランキング</div>
                          <div>
                            <span :class="$style.itemLabel">ベスト</span>
                            <span><a-checkbox value="6">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="7">車両</a-checkbox></span>
                          </div>
                          <div>
                            <span :class="$style.itemLabel">ワースト</span>
                            <span><a-checkbox value="8">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="9">車両</a-checkbox></span>
                          </div>
                        </div>
                      </div>
                    </a-checkbox-group>
                  </a-form-model-item>
                  <a-form-model-item ref="emailContentDistanceRate" label="違反数 / 運転距離" prop="emailContentDistanceRate" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-checkbox-group :default-value="emailContentDistanceRate" @change="onChangeEmailContentDistanceRate">
                      <div>
                        <div :class="$style.rankingItemContainer">
                          <div :class="$style.rankingItemTitle">全社ランキング</div>
                          <div>
                            <span :class="$style.itemLabel">ベスト</span>
                            <span><a-checkbox value="0">支店</a-checkbox></span>
                            <span><a-checkbox value="1">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="2">車両</a-checkbox></span>
                          </div>
                          <div>
                            <span :class="$style.itemLabel">ワースト</span>
                            <span><a-checkbox value="3">支店</a-checkbox></span>
                            <span><a-checkbox value="4">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="5">車両</a-checkbox></span>
                          </div>
                        </div>
                        <div :class="$style.rankingItemContainer">
                          <div :class="$style.rankingItemTitle">支店ランキング</div>
                          <div>
                            <span :class="$style.itemLabel">ベスト</span>
                            <span><a-checkbox value="6">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="7">車両</a-checkbox></span>
                          </div>
                          <div>
                            <span :class="$style.itemLabel">ワースト</span>
                            <span><a-checkbox value="8">ユーザー</a-checkbox></span>
                            <span><a-checkbox value="9">車両</a-checkbox></span>
                          </div>
                        </div>
                      </div>
                    </a-checkbox-group>
                  </a-form-model-item>
                  </div>
                </a-form-model>
              </div>
            </div>
          </section>
          <p class="text-center" :class="$style.buttonContainer">
            <a-button :loading="noticeEmailSettingButtonLoading" type="primary" @click="onSubmitnoticeEmailSetting" :class="$style.filledBtn">
              更新
            </a-button>
          </p>
        </div>
      </div>
    </div>

    <div :class="$style.cardContainer" v-if="plus">
      <section class="card">
        <div class="card-header">
          <h2>過労運転管理の表示</h2>
        </div>
        <div class="card-body">
          <div v-if="showCautionDriverLoading" class="text-center">
            <a-spin tip="Loading..." />
          </div>
          <div v-else :class="$style.notificationMailSettings">
            <a-radio-group v-model="showCautionDriver">
              <a-radio :value="1">表示する</a-radio>
              <a-radio :value="0">表示しない</a-radio>
            </a-radio-group>
          </div>
        </div>
      </section>
      <p class="text-center" v-if="!showCautionDriverLoading" :class="$style.buttonContainer">
        <a-button :loading="showCautionDriverButtonLoading" type="primary" @click="onSubmitShowCautionDriver" :class="$style.filledBtn">
          更新
        </a-button>
      </p>
    </div>

    <a-modal
      :title="noticeEmailAddressTitle"
      :visible="visibleNoticeEmailAddress"
      @cancel="handleCancelNoticeEmailAddress"
    >
      <div>
        <a-form-model
          ref="noticeEmailAddressRef"
          :rules="NoticeEmailAddressRules"
          :model="NoticeEmailAddressForm"
        >
          <a-form-model-item ref="email" label="メールアドレス" prop="email">
            <a-input
              v-model="NoticeEmailAddressForm.email"
              placeholder="mail@address.com"
              @blur="
                () => {
                  $refs.email.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetNoticeEmailAddressId" key="submit" type="danger" :loading="confirmNoticeEmailAddressLoading" @click="handleDeleteNoticeEmailAddress">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelNoticeEmailAddress">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmNoticeEmailAddressLoading" @click="handleOkNoticeEmailAddress">
              {{ noticeEmailAddressOkText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="freeItemAlcoholCheckTitle"
      :visible="visibleFreeItemAlcoholCheck"
      @cancel="handleCancelFreeItemAlcoholCheck"
    >
      <div>
        <a-form-model
          ref="freeItemAlcoholCheckRef"
          :rules="freeItemAlcoholCheckRules"
          :model="freeItemAlcoholCheckForm"
        >
          <a-form-model-item ref="name" label="項目名" prop="name">
            <a-input
              v-model="freeItemAlcoholCheckForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item ref="show_timing" label="表示タイミング" prop="show_timing">
            <a-radio-group v-model="freeItemAlcoholCheckForm.show_timing">
              <a-radio :value="0">乗車前後</a-radio>
              <a-radio :value="1">乗車前のみ</a-radio>
              <a-radio :value="2">乗車後のみ</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="required_item" label="回答" prop="required_item">
            <a-radio-group v-model="freeItemAlcoholCheckForm.required_item">
              <a-radio :value="0">任意</a-radio>
              <a-radio :value="1">必須</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="edit_self_flag" label="本人による編集の許可" prop="edit_self_flag">
            <a-radio-group v-model="freeItemAlcoholCheckForm.edit_self_flag">
              <a-radio :value="0">許可しない</a-radio>
              <a-radio :value="1">許可する</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="priority" label="表示優先度" prop="priority">
            <a-input-number v-model="freeItemAlcoholCheckForm.priority" :min="0" /><br>
            <p>※数値が低いほうが左側に表示されます。</p>
          </a-form-model-item>

          <a-form-model-item ref="choice" label="選択肢" prop="choice" extra="|| で区切ってください。（例: 択1||択2）">
            <a-input
              v-model="freeItemAlcoholCheckForm.choice"
              @blur="
                () => {
                  $refs.choice.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetFreeItemAlcoholCheckId" key="submit" type="danger" :loading="confirmFreeItemAlcoholCheckLoading" @click="handleDeleteFreeItemAlcoholCheck">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelFreeItemAlcoholCheck">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmFreeItemAlcoholCheckLoading" @click="handleOkFreeItemAlcoholCheck">
              {{ modalFreeItemAlcoholCheckText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <!-- 運転前点検項目 作成/編集モーダル -->
    <a-modal
      :title="inspectionItemTitle"
      :visible="visibleInspectionItem"
      @cancel="handleCancelInspectionItem"
    >
      <div>
        <a-form-model
          ref="inspectionItemRef"
          :rules="inspectionItemRules"
          :model="inspectionItemForm"
        >
          <a-form-model-item v-if="!inspectionLockedItem" ref="name" label="項目名" prop="name">
            <a-input v-model="inspectionItemForm.name" />
          </a-form-model-item>
          <a-form-model-item v-if="!inspectionLockedItem" ref="category" label="カテゴリ" prop="category">
            <a-auto-complete
              v-model="inspectionItemForm.category"
              :data-source="inspectionItemCategories"
              :filter-option="filterOption" />
          </a-form-model-item>
          <a-form-model-item v-if="!inspectionLockedItem" ref="description" label="説明文" prop="description">
            <a-textarea
              v-model="inspectionItemForm.description"
              :auto-size="{ minRows: 3, maxRows: 5 }" />
          </a-form-model-item>
          <a-form-model-item ref="required_item" label="回答" prop="required_item">
            <a-radio-group v-model="inspectionItemForm.required_item">
              <a-radio :value="0">任意</a-radio>
              <a-radio :value="1">必須</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="!inspectionLockedItem" ref="priority" label="表示優先度" prop="priority">
            <a-input-number v-model="inspectionItemForm.priority" :min="0" />
            <br>
            <p>※数値が低いほうが上側に表示されます。</p>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button
              v-if="targetInspectionItemId !== 0 && !inspectionLockedItem" key="submit" type="danger"
              :loading="confirmInspectionItemLoading" @click="handleDeleteInspectionItem">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelInspectionItem">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmInspectionItemLoading" @click="handleOkInspectionItem">
              {{ modalInspectionItemText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="freeItemUserTitle"
      :visible="visibleFreeItemUser"
      @cancel="handleCancelFreeItemUser"
    >
      <div>
        <a-form-model
          ref="freeItemUserRef"
          :rules="FreeItemUserRules"
          :model="FreeItemUserForm"
        >
          <a-form-model-item ref="name" label="項目名" prop="name">
            <a-input
              v-model="FreeItemUserForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item ref="hide_drive_data_list" label="運行履歴への表示" prop="hide_drive_data_list">
            <a-radio-group v-model="FreeItemUserForm.hide_drive_data_list">
              <a-radio :value="0">表示する</a-radio>
              <a-radio :value="1">表示しない</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="priority" label="表示優先度" prop="priority">
            <a-input-number v-model="FreeItemUserForm.priority" :min="0" /><br>
            <p>※数値が低いほうが優先されます。</p>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetFreeItemUserId" key="submit" type="danger" :loading="confirmFreeItemUserLoading" @click="handleDeleteFreeItemUser">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelFreeItemUser">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmFreeItemUserLoading" @click="handleOkFreeItemUser">
              {{ freeItemUserOkText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="drivingPurposeTitle"
      :visible="visibleDrivingPurpose"
      @cancel="handleCancelDrivingPurpose"
    >
      <div>
        <a-form-model
          ref="drivingPurposeRef"
          :rules="DrivingPurposeRules"
          :model="drivingPurposeForm"
        >
          <a-form-model-item ref="name" label="項目名" prop="name">
            <a-input
              v-model="drivingPurposeForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetDrivingPurposeId" key="submit" type="danger" :loading="confirmDrivingPurposeLoading" @click="handleDeleteDrivingPurpose">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelDrivingPurpose">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmDrivingPurposeLoading" @click="handleOkDrivingPurpose">
              {{ drivingPurposeOkText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import storeVue from '@/store'
import moment from 'moment'
import { enumData } from '@/services/enum'

export default {
  data() {
    return {
      useAnnounce: false,
      useAnalyze: false,
      isFleet: true,
      typeOptions: enumData.violationType,
      announceOptions: enumData.announcementType,

      excludeViolationsLoading: true,
      excludeViolationCheckList: [],
      excludeViolationButtonLoading: false,

      excludeAnnouncementLoading: true,
      excludeAnnouncementCheckList: [],
      excludeAnnouncementButtonLoading: false,

      announcementThresholdLoading: false,
      announcementThresholdForm: {
        maximumSpeed: 100.00,
        maximumSpeedZone30: 100.00,
        temporaryStop: 100.00,
        railroadStop: 100.00,
      },
      announcementThresholdRules: {
        maximumSpeed: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        maximumSpeedZone30: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        temporaryStop: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        railroadStop: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
      },
      announcementThresholdButtonLoading: false,

      noticeEmailAddressLoading: true,
      noticeEmailAddressList: [],
      NoticeEmailAddressForm: {
        email: '',
      },
      NoticeEmailAddressRules: {
        email: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'email', message: 'メールアドレスを入力してください。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
      },
      noticeEmailAddressTitle: '通知メールアドレスの更新',
      visibleNoticeEmailAddress: false,
      confirmNoticeEmailAddressLoading: false,
      noticeEmailAddressOkText: '更新',
      targetNoticeEmailAddressId: 0,

      noticeEmailSettingLoading: true,
      noticeEmailSettingButtonLoading: false,
      noticeEmailSettingForm: {
        emailSendTime: '07:00:00',
        numberOfRanking: 10,
        emailContentComplianceRate: '1111110000',
        emailContentTimeRate: '0000000000',
        emailContentDistanceRate: '0000000000',
      },
      emailSendTimeMoment: moment('07:00:00', 'HH:mm:ss'),
      emailContentComplianceRate: ['0', '1', '2', '3', '4', '5'],
      emailContentTimeRate: [],
      emailContentDistanceRate: [],
      noticeEmailSettingRules: {
        numberOfRanking: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        emailSendTime: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },

      alcoholThresholdLoading: true,
      alcoholThreshold: 0,
      alcoholThresholdButtonLoading: false,

      alcoholMeasurementHideLoading: true,
      alcoholMeasurementHide: 0,
      alcoholMeasurementHideButtonLoading: false,

      freeItemAlcoholCheckLoading: true,
      freeItemAlcoholCheckButtonLoading: false,
      freeItemAlcoholCheckList: [],
      visibleFreeItemAlcoholCheck: false,
      freeItemAlcoholCheckForm: {
        name: '',
        show_timing: 0,
        required_item: 0,
        edit_self_flag: 0,
        priority: 0,
        choice: '',
      },
      freeItemAlcoholCheckRules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
        priority: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        edit_self_flag: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        show_timing: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        required_item: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },
      showTimingChoice: ['乗車前後', '乗車前のみ', '乗車後のみ'],
      freeItemAlcoholCheckTitle: '点呼記録簿の自由項目の作成',
      modalFreeItemAlcoholCheckText: '作成',
      targetFreeItemAlcoholCheckId: 0,
      confirmFreeItemAlcoholCheckLoading: false,

      inspectionItemLoading: true,
      inspectionItemButtonLoading: false,
      inspectionItemList: [],
      visibleInspectionItem: false,
      inspectionItemForm: {
        name: '',
        category: '',
        description: '',
        required_item: 0,
        priority: 0,
      },
      inspectionItemTitle: '運行前点検項目の作成',
      modalInspectionItemText: '作成',
      inspectionLockedItem: false,
      targetInspectionItemId: 0,
      confirmInspectionItemLoading: false,

      freeItemUserLoading: true,
      freeItemUserButtonLoading: false,
      freeItemUserList: [],
      visibleFreeItemUser: false,
      FreeItemUserForm: {
        name: '',
        hide_drive_data_list: 0,
        priority: 0,
      },
      FreeItemUserRules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
        hide_drive_data_list: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        priority: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
      },
      freeItemUserTitle: 'ユーザーの自由項目の更新',
      targetFreeItemUserId: 0,
      confirmFreeItemUserLoading: false,
      freeItemUserOkText: '更新',

      drivingPurposeLoading: true,
      drivingPurposeButtonLoading: false,
      drivingPurposeList: [],
      visibleDrivingPurpose: false,
      drivingPurposeForm: {
        name: '',
      },
      DrivingPurposeRules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
      },
      drivingPurposeTitle: '運転日報内の運転目的項目の更新',
      targetDrivingPurposeId: 0,
      confirmDrivingPurposeLoading: false,
      drivingPurposeOkText: '更新',

      showCautionDriverLoading: true,
      showCautionDriverButtonLoading: false,
      showCautionDriver: 0,

      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }
  },
  computed: {
    // 登録済み運転前点検項目のカテゴリ（自動補完用）
    inspectionItemCategories() {
      const categories = this.inspectionItemList
        .map(item => item.category)
        .filter(category => category)
      // 重複除去
      return Array.from(new Set(categories))
    },
    // 運転前点検項目のバリデーションルール
    inspectionItemRules() {
      const rules = {
        required_item: [{ required: true, message: '必須項目です。', trigger: 'blur' }],
      }
      if (this.targetInspectionItemId === 0 || !this.inspectionLockedItem) {
        rules.name = [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ]
        rules.category = [{ max: 1024, message: '1024文字までです。', trigger: 'blur' }]
        rules.priority = [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ]
      }
      return rules
    },

    plus() {
      return storeVue.getters.plus_flag
    },
  },
  created() {
    const companyGet = Vue.prototype.$api.send('get', 'company')
    companyGet.then(response => {
      console.log(response.alcohol_threshold)
      this.alcoholThreshold = Number(response.alcohol_threshold)
      this.alcoholMeasurementHide = response.alcohol_measurement_hide

      this.excludeViolationCheckList = response.exclude_violations.map(v => v.violation_type)
      this.excludeViolationsLoading = false

      this.useAnnounce = response.use_announce
      this.useAnalyze = response.use_analyze
      this.excludeAnnouncementCheckList = response.exclude_announcements.map(v => v.code + '-' + v.code_sub)
      this.excludeAnnouncementLoading = false
      if (response.plan !== 6) this.isFleet = false

      this.announcementThresholdForm.maximumSpeed = response.announce_threshold_maximum_speed
      this.announcementThresholdForm.maximumSpeedZone30 = response.announce_threshold_maximum_speed_zone30
      this.announcementThresholdForm.temporaryStop = response.announce_threshold_temporary_stop
      this.announcementThresholdForm.railroadStop = response.announce_threshold_railroad_stop

      this.noticeEmailSettingForm.emailSendTime = response.email_send_time
      this.emailSendTimeMoment = moment(response.email_send_time, 'HH:mm:ss')
      this.noticeEmailSettingForm.numberOfRanking = response.email_number_of_ranking
      this.noticeEmailSettingForm.emailContentComplianceRate = response.email_content_compliance_rate
      this.noticeEmailSettingForm.emailContentTimeRate = response.email_content_time_rate
      this.noticeEmailSettingForm.emailContentDistanceRate = response.email_content_distance_rate
      const emailContentComplianceRate = []
      const emailContentTimeRate = []
      const emailContentDistanceRate = []
      for (let i = 0; i < 10; i++) {
        if (response.email_content_compliance_rate[i] === '1') emailContentComplianceRate.push(String(i))
        if (response.email_content_time_rate[i] === '1') emailContentTimeRate.push(String(i))
        if (response.email_content_distance_rate[i] === '1') emailContentDistanceRate.push(String(i))
      }
      this.emailContentComplianceRate = emailContentComplianceRate
      this.emailContentTimeRate = emailContentTimeRate
      this.emailContentDistanceRate = emailContentDistanceRate
      this.showCautionDriver = response.show_caution_driver
      this.alcoholThresholdLoading = false
      this.alcoholMeasurementHideLoading = false
      this.noticeEmailSettingLoading = false
      this.showCautionDriverLoading = false

      console.log('companyGet', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 除外違反項目の取得に失敗しました。',
        })
        this.excludeViolationsLoading = false
      })

    const noticeEmailAddressResult = Vue.prototype.$api.send('get', 'notice_email_address')
    noticeEmailAddressResult.then(response => {
      this.noticeEmailAddressList = response
      this.noticeEmailAddressLoading = false
      console.log('noticeEmailAddressResult', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 通知メールアドレスの取得に失敗しました。',
        })
        this.noticeEmailAddressLoading = false
      })

    const freeItemAlcoholCheckResult = Vue.prototype.$api.send('get', 'free_item_alcohol_checks')
    freeItemAlcoholCheckResult.then(response => {
      this.freeItemAlcoholCheckList = response
      this.freeItemAlcoholCheckLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 点呼記録簿の自由項目の取得に失敗しました。',
        })
        this.freeItemAlcoholCheckLoading = false
      })

    this.getInspectionItems()

    const freeItemUserResult = Vue.prototype.$api.send('get', 'free_item_users')
    freeItemUserResult.then(response => {
      this.freeItemUserList = response
      this.freeItemUserLoading = false
      console.log('freeItemUserResult', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの自由項目の取得に失敗しました。',
        })
        this.freeItemUserLoading = false
      })

    const drivingPurposeResult = Vue.prototype.$api.send('get', 'driving_purposes')
    drivingPurposeResult.then(response => {
      this.drivingPurposeList = response
      this.drivingPurposeLoading = false
      console.log('drivingPurposeResult', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 運転日報内の運転目的項目の取得に失敗しました。',
        })
        this.drivingPurposeLoading = false
      })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    onSubmitExcludeViolations() {
      const _this = this
      this.excludeViolationButtonLoading = true
      const send = Vue.prototype.$api.send('put', 'exclude_violations', { violations: this.excludeViolationCheckList })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: '除外違反項目を更新しました。',
        })
        this.excludeViolationButtonLoading = false
      })
        .catch(error => {
          if (error.status === 403) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.excludeViolationButtonLoading = false
          console.log(error)
        })
    },
    onSubmitExcludeAnnouncements() {
      const _this = this
      this.excludeAnnouncementButtonLoading = true
      const send = Vue.prototype.$api.send('put', 'exclude_announcements', { announcements: this.excludeAnnouncementCheckList })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: '除外アナウンス項目を更新しました。',
        })
        this.excludeAnnouncementButtonLoading = false
      })
        .catch(error => {
          if (error.status === 403) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.excludeAnnouncementButtonLoading = false
          console.log(error)
        })
    },
    onSubmitAnnouncementThreshold() {
      const _this = this
      this.$refs.announcementThresholdRuleForm.validate(valid => {
        if (valid) {
          this.announcementThresholdButtonLoading = true
          const send = Vue.prototype.$api.send('post', 'announcements_threshold', this.announcementThresholdForm)
          send.then(response => {
            console.log(response)
            _this.$notification['success']({
              message: 'アナウンス閾値項目を設定しました。',
            })
            this.announcementThresholdButtonLoading = false
          })
            .catch(error => {
              if (error.status === 403) {
                _this.$notification['error']({
                  message: error.data.data,
                })
              } else {
                for (var key in error.data.errors) {
                  _this.$refs[key].validateState = 'error'
                  _this.$refs[key].validateMessage = error.data.errors[key]
                }
              }
              this.announcementThresholdButtonLoading = false
              console.log(error)
            })
        }
      })
    },
    onSubmitnoticeEmailSetting() {
      const _this = this
      this.$refs.noticeEmailSettingRuleForm.validate(valid => {
        if (valid) {
          this.noticeEmailSettingButtonLoading = true
          const send = Vue.prototype.$api.send('post', 'notice_email_setting', this.noticeEmailSettingForm)
          send.then(response => {
            console.log(response)
            _this.$notification['success']({
              message: '通知メール設定を更新しました。',
            })
            this.noticeEmailSettingButtonLoading = false
          })
            .catch(error => {
              if (error.status === 403) {
                _this.$notification['error']({
                  message: error.data.data,
                })
              } else {
                for (var key in error.data.errors) {
                  _this.$refs[key].validateState = 'error'
                  _this.$refs[key].validateMessage = error.data.errors[key]
                }
              }
              this.noticeEmailSettingButtonLoading = false
              console.log(error)
            })
        }
      })
    },
    onChangeEmailSendTime(time) {
      if (time) this.noticeEmailSettingForm.emailSendTime = time.format('HH:mm:ss')
    },
    onChangeEmailContentComplianceRate(checkedValues) {
      let list = ''
      for (let i = 0; i < 10; i++) list += checkedValues.includes(String(i)) ? '1' : '0'
      this.noticeEmailSettingForm.emailContentComplianceRate = list
    },
    onChangeEmailContentTimeRate(checkedValues) {
      let list = ''
      for (let i = 0; i < 10; i++) list += checkedValues.includes(String(i)) ? '1' : '0'
      this.noticeEmailSettingForm.emailContentTimeRate = list
    },
    onChangeEmailContentDistanceRate(checkedValues) {
      let list = ''
      for (let i = 0; i < 10; i++) list += checkedValues.includes(String(i)) ? '1' : '0'
      this.noticeEmailSettingForm.emailContentDistanceRate = list
    },
    onMakeNoticeEmailAddress() {
      this.visibleNoticeEmailAddress = true
      this.targetNoticeEmailAddressId = 0
      this.noticeEmailAddressTitle = '通知メールアドレスの作成'
      this.NoticeEmailAddressForm.email = ''
    },
    updateNoticeEmailAddress(id, email) {
      this.visibleNoticeEmailAddress = true
      this.targetNoticeEmailAddressId = id
      this.noticeEmailAddressTitle = '通知メールアドレスの更新'
      this.NoticeEmailAddressForm.email = email
    },
    handleCancelNoticeEmailAddress() { this.visibleNoticeEmailAddress = false },
    handleOkNoticeEmailAddress() {
      const _this = this
      this.confirmNoticeEmailAddressLoading = true
      var actionType = 'post'
      var bodyData = { email: this.NoticeEmailAddressForm.email }
      if (this.targetNoticeEmailAddressId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetNoticeEmailAddressId,
          email: this.NoticeEmailAddressForm.email,
        }
      }

      this.$refs.noticeEmailAddressRef.validate(valid => {
        const sendNoticeEmailAddress = Vue.prototype.$api.send(actionType, 'notice_email_address', bodyData)
        sendNoticeEmailAddress.then(response => {
          _this.$notification['success']({
            message: '通知メールアドレスを更新しました。',
          })
          if (this.targetNoticeEmailAddressId !== 0) {
            var targetUpdateNoticeEmailAddress = this.noticeEmailAddressList.find(e => e.id === this.targetNoticeEmailAddressId)
            targetUpdateNoticeEmailAddress.email = this.NoticeEmailAddressForm.email
          } else {
            this.noticeEmailAddressList.push(response)
          }
          this.visibleNoticeEmailAddress = false
          this.confirmNoticeEmailAddressLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmNoticeEmailAddressLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteNoticeEmailAddress() {
      const _this = this
      this.confirmNoticeEmailAddressLoading = true
      const send = Vue.prototype.$api.send('delete', 'notice_email_address', { id: this.targetNoticeEmailAddressId })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: '通知メールアドレスを削除しました。',
        })
        this.noticeEmailAddressList = this.noticeEmailAddressList.filter(e => e.id !== _this.targetNoticeEmailAddressId)
        this.visibleNoticeEmailAddress = false
        this.confirmNoticeEmailAddressLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleNoticeEmailAddress = false
          this.confirmNoticeEmailAddressLoading = false
          console.log(error)
        })
    },

    onMakeFreeItemAlcoholCheck() {
      this.visibleFreeItemAlcoholCheck = true
      this.targetFreeItemAlcoholCheckId = 0
      this.freeItemAlcoholCheckTitle = '点呼記録簿の自由項目の作成'
      this.modalFreeItemAlcoholCheckText = '作成'
      this.freeItemAlcoholCheckForm.name = ''
      this.freeItemAlcoholCheckForm.show_timing = 0
      this.freeItemAlcoholCheckForm.required_item = 0
      this.freeItemAlcoholCheckForm.edit_self_flag = 0
      this.freeItemAlcoholCheckForm.priority = 0
      this.freeItemAlcoholCheckForm.choice = ''
    },
    updateFreeItemAlcoholCheck(id, name, showTiming, editSelfFlag, priority, choice, requiredItem) {
      this.visibleFreeItemAlcoholCheck = true
      this.targetFreeItemAlcoholCheckId = id
      this.freeItemAlcoholCheckTitle = '点呼記録簿の自由項目の更新'
      this.modalFreeItemAlcoholCheckText = '更新'
      this.freeItemAlcoholCheckForm.name = name
      this.freeItemAlcoholCheckForm.show_timing = showTiming
      this.freeItemAlcoholCheckForm.required_item = requiredItem
      this.freeItemAlcoholCheckForm.edit_self_flag = editSelfFlag
      this.freeItemAlcoholCheckForm.priority = priority
      this.freeItemAlcoholCheckForm.choice = choice || ''
    },
    handleCancelFreeItemAlcoholCheck() { this.visibleFreeItemAlcoholCheck = false },
    handleOkFreeItemAlcoholCheck() {
      const _this = this
      this.confirmFreeItemAlcoholCheckLoading = true
      var actionType = 'post'
      var bodyData = {
        name: this.freeItemAlcoholCheckForm.name,
        show_timing: this.freeItemAlcoholCheckForm.show_timing,
        required_item: this.freeItemAlcoholCheckForm.required_item,
        edit_self_flag: this.freeItemAlcoholCheckForm.edit_self_flag,
        priority: this.freeItemAlcoholCheckForm.priority,
        choice: this.freeItemAlcoholCheckForm.choice,
      }
      if (this.targetFreeItemAlcoholCheckId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetFreeItemAlcoholCheckId,
          name: this.freeItemAlcoholCheckForm.name,
          show_timing: this.freeItemAlcoholCheckForm.show_timing,
          required_item: this.freeItemAlcoholCheckForm.required_item,
          edit_self_flag: this.freeItemAlcoholCheckForm.edit_self_flag,
          priority: this.freeItemAlcoholCheckForm.priority,
          choice: this.freeItemAlcoholCheckForm.choice,
        }
      }

      this.$refs.freeItemAlcoholCheckRef.validate(valid => {
        const sendFreeItemAlcoholCheck = Vue.prototype.$api.send(actionType, 'free_item_alcohol_check', bodyData)
        sendFreeItemAlcoholCheck.then(response => {
          _this.$notification['success']({
            message: '点呼記録簿の自由項目を更新しました。',
          })
          if (this.targetFreeItemAlcoholCheckId !== 0) {
            var targetUpdateFreeItemAlcoholCheck = this.freeItemAlcoholCheckList.find(e => e.id === this.targetFreeItemAlcoholCheckId)
            targetUpdateFreeItemAlcoholCheck.name = this.freeItemAlcoholCheckForm.name
            targetUpdateFreeItemAlcoholCheck.show_timing = this.freeItemAlcoholCheckForm.show_timing
            targetUpdateFreeItemAlcoholCheck.required_item = this.freeItemAlcoholCheckForm.required_item
            targetUpdateFreeItemAlcoholCheck.edit_self_flag = this.freeItemAlcoholCheckForm.edit_self_flag
            targetUpdateFreeItemAlcoholCheck.priority = this.freeItemAlcoholCheckForm.priority
            targetUpdateFreeItemAlcoholCheck.choice = this.freeItemAlcoholCheckForm.choice
          } else {
            this.freeItemAlcoholCheckList.push(response)
          }
          this.visibleFreeItemAlcoholCheck = false
          this.confirmFreeItemAlcoholCheckLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmFreeItemAlcoholCheckLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteFreeItemAlcoholCheck() {
      const _this = this
      this.confirmFreeItemAlcoholCheckLoading = true
      const send = Vue.prototype.$api.send('delete', 'free_item_alcohol_check', { id: this.targetFreeItemAlcoholCheckId })
      send.then(response => {
        _this.$notification['success']({
          message: '点呼記録簿の自由項目を削除しました。',
        })
        this.freeItemAlcoholCheckList = this.freeItemAlcoholCheckList.filter(e => e.id !== _this.targetFreeItemAlcoholCheckId)
        this.visibleFreeItemAlcoholCheck = false
        this.confirmFreeItemAlcoholCheckLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleFreeItemAlcoholCheck = false
          this.confirmFreeItemAlcoholCheckLoading = false
          console.log(error)
        })
    },

    // 運行前項目を取得する
    getInspectionItems() {
      this.inspectionItemLoading = true
      Vue.prototype.$api.send('get', 'inspection_items')
        .then(response => {
          this.inspectionItemList = response
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 運行前項目の取得に失敗しました。',
          })
        })
        .finally(() => {
          this.inspectionItemLoading = false
        })
    },
    // 運行前点検項目の追加モーダル表示
    onMakeInspectionItem() {
      this.visibleInspectionItem = true
      this.targetInspectionItemId = 0
      this.inspectionItemTitle = '運行前点検項目の作成'
      this.modalInspectionItemText = '作成'
      this.inspectionLockedItem = false
      this.inspectionItemForm.name = ''
      this.inspectionItemForm.category = ''
      this.inspectionItemForm.description = ''
      this.inspectionItemForm.required_item = 1
      this.inspectionItemForm.priority = 0
      try {
        this.$refs.inspectionItemRef.clearValidate()
      } catch (error) {}
    },
    // 運行前点検項目の更新モーダル表示
    onUpdateInspectionItem(item) {
      this.visibleInspectionItem = true
      this.targetInspectionItemId = item.id
      this.modalInspectionItemText = '更新'
      this.inspectionLockedItem = item.locked_item === 1
      this.inspectionItemTitle = '運行前点検項目の更新'
      this.inspectionItemForm.name = item.name
      this.inspectionItemForm.category = item.category
      this.inspectionItemForm.description = item.description
      this.inspectionItemForm.required_item = item.required_item
      this.inspectionItemForm.priority = item.priority
      try {
        this.$refs.inspectionItemRef.clearValidate()
      } catch (error) {}
    },
    // デフォルトの運行前項目の作成実行
    onMakeDefaultInspectionItem() {
      this.inspectionItemButtonLoading = true
      Vue.prototype.$api.send('post', 'inspection_items/make_default')
        .then(_response => {
          this.getInspectionItems()
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': デフォルトの運行前点検項目の作成に失敗しました。',
          })
        })
        .finally(() => {
          this.inspectionItemButtonLoading = false
        })
    },
    handleCancelInspectionItem() { this.visibleInspectionItem = false },
    // 運行前点検項目の作成/更新実行
    handleOkInspectionItem() {
      this.$refs.inspectionItemRef.validate(valid => {
        if (!valid) return

        const bodyData = {
          name: this.inspectionItemForm.name,
          category: this.inspectionItemForm.category,
          description: this.inspectionItemForm.description,
          required_item: this.inspectionItemForm.required_item,
          priority: this.inspectionItemForm.priority,
        }
        if (this.targetInspectionItemId !== 0) {
          bodyData.id = this.targetInspectionItemId
        }

        this.confirmInspectionItemLoading = true
        Vue.prototype.$api.send(
          this.targetInspectionItemId === 0 ? 'post' : 'put',
          'inspection_items',
          bodyData,
        ).then(_response => {
          this.$notification['success']({
            message: `運行前点検項目を${this.modalInspectionItemText}しました。`,
          })
          this.getInspectionItems()
          this.visibleInspectionItem = false
        })
          .catch(error => {
            if (error.status === 403) {
              this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                this.$refs[key].validateState = 'error'
                this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            console.log(error)
          })
          .finally(() => {
            this.confirmInspectionItemLoading = false
          })
      })
    },
    // 運行前点検項目の削除実行
    handleDeleteInspectionItem() {
      this.confirmInspectionItemLoading = true
      Vue.prototype.$api.send('delete', 'inspection_items', { id: this.targetInspectionItemId })
        .then(_response => {
          this.$notification['success']({
            message: '運行前点検項目を削除しました。',
          })
          this.inspectionItemList = this.inspectionItemList.filter(e => e.id !== this.targetInspectionItemId)
        })
        .catch(error => {
          this.$notification['error']({
            message: error.data.data,
          })
          console.log(error)
        })
        .finally(() => {
          this.visibleInspectionItem = false
          this.confirmInspectionItemLoading = false
        })
    },

    onMakeFreeItemUser() {
      this.visibleFreeItemUser = true
      this.targetFreeItemUserId = 0
      this.freeItemUserTitle = 'ユーザーの自由項目の作成'
      this.FreeItemUserForm.name = ''
      this.FreeItemUserForm.hide_drive_data_list = 0
      this.FreeItemUserForm.priority = 0
    },
    updateFreeItemUser(id, name, hideDriveDataList, priority) {
      this.visibleFreeItemUser = true
      this.targetFreeItemUserId = id
      this.freeItemUserTitle = 'ユーザーの自由項目の更新'
      this.FreeItemUserForm.name = name
      this.FreeItemUserForm.hide_drive_data_list = hideDriveDataList
      this.FreeItemUserForm.priority = priority
    },
    handleCancelFreeItemUser() { this.visibleFreeItemUser = false },
    handleOkFreeItemUser() {
      const _this = this
      this.confirmFreeItemUserLoading = true
      var actionType = 'post'
      var bodyData = {
        name: this.FreeItemUserForm.name,
        hide_drive_data_list: this.FreeItemUserForm.hide_drive_data_list,
        priority: this.FreeItemUserForm.priority,
      }
      if (this.targetFreeItemUserId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetFreeItemUserId,
          name: this.FreeItemUserForm.name,
          hide_drive_data_list: this.FreeItemUserForm.hide_drive_data_list,
          priority: this.FreeItemUserForm.priority,
        }
      }

      this.$refs.freeItemUserRef.validate(valid => {
        const sendFreeItemUser = Vue.prototype.$api.send(actionType, 'free_item_user', bodyData)
        sendFreeItemUser.then(response => {
          _this.$notification['success']({
            message: 'ユーザーの自由項目を更新しました。',
          })
          if (this.targetFreeItemUserId !== 0) {
            var targetUpdateFreeItemUser = this.freeItemUserList.find(e => e.id === this.targetFreeItemUserId)
            targetUpdateFreeItemUser.name = this.FreeItemUserForm.name
            targetUpdateFreeItemUser.hide_drive_data_list = this.FreeItemUserForm.hide_drive_data_list
            targetUpdateFreeItemUser.priority = this.FreeItemUserForm.priority
          } else {
            this.freeItemUserList.push(response)
          }
          this.visibleFreeItemUser = false
          this.confirmFreeItemUserLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmFreeItemUserLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteFreeItemUser() {
      const _this = this
      this.confirmFreeItemUserLoading = true
      const send = Vue.prototype.$api.send('delete', 'free_item_user', { id: this.targetFreeItemUserId })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: 'ユーザーの自由項目を削除しました。',
        })
        this.freeItemUserList = this.freeItemUserList.filter(e => e.id !== _this.targetFreeItemUserId)
        this.visibleFreeItemUser = false
        this.confirmFreeItemUserLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleFreeItemUser = false
          this.confirmFreeItemUserLoading = false
          console.log(error)
        })
    },

    onMakeDrivingPurpose() {
      this.visibleDrivingPurpose = true
      this.targetDrivingPurposeId = 0
      this.drivingPurposeTitle = '運転日報内の運転目的項目の作成'
      this.drivingPurposeForm.name = ''
      this.drivingPurposeOkText = '作成'
    },
    updateDrivingPurpose(id, name, hideDriveDataList, priority) {
      this.visibleDrivingPurpose = true
      this.targetDrivingPurposeId = id
      this.drivingPurposeTitle = '運転日報内の運転目的項目の更新'
      this.drivingPurposeForm.name = name
      this.drivingPurposeOkText = '更新'
    },
    handleCancelDrivingPurpose() { this.visibleDrivingPurpose = false },
    handleOkDrivingPurpose() {
      const _this = this
      this.confirmDrivingPurposeLoading = true
      var actionType = 'post'
      var bodyData = {
        name: this.drivingPurposeForm.name,
      }
      if (this.targetDrivingPurposeId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetDrivingPurposeId,
          name: this.drivingPurposeForm.name,
        }
      }

      this.$refs.drivingPurposeRef.validate(valid => {
        const sendDrivingPurpose = Vue.prototype.$api.send(actionType, 'driving_purpose', bodyData)
        sendDrivingPurpose.then(response => {
          _this.$notification['success']({
            message: '運転日報内の運転目的項目を更新しました。',
          })
          if (this.targetDrivingPurposeId !== 0) {
            var targetUpdateDrivingPurpose = this.drivingPurposeList.find(e => e.id === this.targetDrivingPurposeId)
            targetUpdateDrivingPurpose.name = this.drivingPurposeForm.name
          } else {
            this.drivingPurposeList.push(response)
          }
          this.visibleDrivingPurpose = false
          this.confirmDrivingPurposeLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmDrivingPurposeLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteDrivingPurpose() {
      const _this = this
      this.confirmDrivingPurposeLoading = true
      const send = Vue.prototype.$api.send('delete', 'driving_purpose', { id: this.targetDrivingPurposeId })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: '運転日報内の運転目的項目を削除しました。',
        })
        this.drivingPurposeList = this.drivingPurposeList.filter(e => e.id !== _this.targetDrivingPurposeId)
        this.visibleDrivingPurpose = false
        this.confirmDrivingPurposeLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleDrivingPurpose = false
          this.confirmDrivingPurposeLoading = false
          console.log(error)
        })
    },

    onSubmitalcoholThreshold() {
      const _this = this
      this.alcoholThresholdButtonLoading = true
      const send = Vue.prototype.$api.send('put', 'alcohol_threshold', { alcohol_threshold: this.alcoholThreshold })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: 'アルコール濃度閾値を更新しました。',
        })
        this.alcoholThresholdButtonLoading = false
      })
        .catch(error => {
          if (error.status === 403) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholThresholdButtonLoading = false
          console.log(error)
        })
    },

    onSubmitalcoholMeasurementHide() {
      const _this = this
      this.alcoholMeasurementHideButtonLoading = true
      const send = Vue.prototype.$api.send('put', 'alcohol_measurement_hide', { alcohol_measurement_hide: this.alcoholMeasurementHide })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: 'アルコール濃度表示を更新しました。',
        })
        this.alcoholMeasurementHideButtonLoading = false
      })
        .catch(error => {
          if (error.status === 403) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholMeasurementHideButtonLoading = false
          console.log(error)
        })
    },
    onSubmitShowCautionDriver() {
      this.showCautionDriverButtonLoading = true
      Vue.prototype.$api.send(
        'put', 'show_caution_driver',
        { show_caution_driver: this.showCautionDriver },
      )
        .then(response => {
          this.$notification['success']({
            message: '過労運転管理表示を更新しました。',
          })
          this.$store.commit('UPDATE_COMPANY_INFO', { show_caution_driver: this.showCautionDriver })
        })
        .catch(error => {
          if (error.status === 403) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          console.log(error)
        })
        .finally(() => {
          this.showCautionDriverButtonLoading = false
        })
    },
  },
}
</script>
