var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("設定")]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _vm.useAnalyze && !_vm.isFleet
            ? _c("div", { class: _vm.$style.cardContainer }, [
                _c("section", { staticClass: "card" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "card-body" }, [
                    _vm.excludeViolationsLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [_c("a-spin", { attrs: { tip: "Loading..." } })],
                          1
                        )
                      : _vm._e(),
                    !_vm.excludeViolationsLoading
                      ? _c(
                          "div",
                          [
                            _c(
                              "a-checkbox-group",
                              {
                                class: _vm.$style.excViolationCheckList,
                                model: {
                                  value: _vm.excludeViolationCheckList,
                                  callback: function($$v) {
                                    _vm.excludeViolationCheckList = $$v
                                  },
                                  expression: "excludeViolationCheckList"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(_vm.typeOptions, function(item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.value,
                                        staticClass: "col-lg-3"
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              disabled: item.disabled,
                                              value: item.value
                                            }
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                !_vm.excludeViolationsLoading
                  ? _c(
                      "p",
                      {
                        staticClass: "text-center",
                        class: _vm.$style.buttonContainer
                      },
                      [
                        _c(
                          "a-button",
                          {
                            class: _vm.$style.filledBtn,
                            attrs: {
                              loading: _vm.excludeViolationButtonLoading
                            },
                            on: { click: _vm.onSubmitExcludeViolations }
                          },
                          [_vm._v("\n            更新\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.useAnnounce && !_vm.isFleet
            ? _c("div", { class: _vm.$style.cardContainer }, [
                _c("section", { staticClass: "card" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "card-body" }, [
                    _vm.excludeAnnouncementLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [_c("a-spin", { attrs: { tip: "Loading..." } })],
                          1
                        )
                      : _vm._e(),
                    !_vm.excludeAnnouncementLoading
                      ? _c(
                          "div",
                          [
                            _c(
                              "a-checkbox-group",
                              {
                                class: _vm.$style.excViolationCheckList,
                                model: {
                                  value: _vm.excludeAnnouncementCheckList,
                                  callback: function($$v) {
                                    _vm.excludeAnnouncementCheckList = $$v
                                  },
                                  expression: "excludeAnnouncementCheckList"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(_vm.announceOptions, function(item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.value,
                                        staticClass: "col-lg-2"
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              disabled: item.disabled,
                                              value: item.value
                                            }
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                !_vm.excludeAnnouncementLoading
                  ? _c(
                      "p",
                      {
                        staticClass: "text-center",
                        class: _vm.$style.buttonContainer
                      },
                      [
                        _c(
                          "a-button",
                          {
                            class: _vm.$style.filledBtn,
                            attrs: {
                              loading: _vm.excludeAnnouncementButtonLoading,
                              type: "primary"
                            },
                            on: { click: _vm.onSubmitExcludeAnnouncements }
                          },
                          [_vm._v("\n            更新\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.useAnnounce && !_vm.isFleet
            ? _c("div", { class: _vm.$style.cardContainer }, [
                _c("section", { staticClass: "card" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "card-body" }, [
                    _vm.announcementThresholdLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [_c("a-spin", { attrs: { tip: "Loading..." } })],
                          1
                        )
                      : _vm._e(),
                    !_vm.announcementThresholdLoading
                      ? _c(
                          "div",
                          [
                            _c(
                              "a-form-model",
                              {
                                ref: "announcementThresholdRuleForm",
                                attrs: {
                                  model: _vm.announcementThresholdForm,
                                  rules: _vm.announcementThresholdRules,
                                  layout: "inline",
                                  hideRequiredMark: true
                                }
                              },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    ref: "maximumSpeed",
                                    attrs: {
                                      label: "速度超過",
                                      prop: "maximumSpeed"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: _vm.$style.requiredMark },
                                      [_vm._v("必須")]
                                    ),
                                    _c("a-input-number", {
                                      attrs: { min: 0, max: 100 },
                                      model: {
                                        value:
                                          _vm.announcementThresholdForm
                                            .maximumSpeed,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.announcementThresholdForm,
                                            "maximumSpeed",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "announcementThresholdForm.maximumSpeed"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    ref: "maximumSpeedZone30",
                                    attrs: {
                                      label: "速度超過（ゾーン30）",
                                      prop: "maximumSpeedZone30"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: _vm.$style.requiredMark },
                                      [_vm._v("必須")]
                                    ),
                                    _c("a-input-number", {
                                      attrs: { min: 0, max: 100 },
                                      model: {
                                        value:
                                          _vm.announcementThresholdForm
                                            .maximumSpeedZone30,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.announcementThresholdForm,
                                            "maximumSpeedZone30",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "announcementThresholdForm.maximumSpeedZone30"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    ref: "temporaryStop",
                                    attrs: {
                                      label: "一時停止",
                                      prop: "temporaryStop"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: _vm.$style.requiredMark },
                                      [_vm._v("必須")]
                                    ),
                                    _c("a-input-number", {
                                      attrs: { min: 0, max: 100 },
                                      model: {
                                        value:
                                          _vm.announcementThresholdForm
                                            .temporaryStop,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.announcementThresholdForm,
                                            "temporaryStop",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "announcementThresholdForm.temporaryStop"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    ref: "railroadStop",
                                    attrs: {
                                      label: "踏切不停止",
                                      prop: "railroadStop"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: _vm.$style.requiredMark },
                                      [_vm._v("必須")]
                                    ),
                                    _c("a-input-number", {
                                      attrs: { min: 0, max: 100 },
                                      model: {
                                        value:
                                          _vm.announcementThresholdForm
                                            .railroadStop,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.announcementThresholdForm,
                                            "railroadStop",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "announcementThresholdForm.railroadStop"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                !_vm.announcementThresholdLoading
                  ? _c(
                      "p",
                      {
                        staticClass: "text-center",
                        class: _vm.$style.buttonContainer
                      },
                      [
                        _c(
                          "a-button",
                          {
                            class: _vm.$style.filledBtn,
                            attrs: {
                              loading: _vm.announcementThresholdButtonLoading,
                              type: "primary"
                            },
                            on: { click: _vm.onSubmitAnnouncementThreshold }
                          },
                          [_vm._v("\n            更新\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c("div", { class: _vm.$style.cardContainer }, [
            _c("section", { staticClass: "card" }, [
              _vm._m(3),
              _c("div", { staticClass: "card-body" }, [
                _vm.alcoholThresholdLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.alcoholThresholdLoading
                  ? _c(
                      "div",
                      { class: _vm.$style.notificationMailSettings },
                      [
                        _c(
                          "a-form-model",
                          {
                            ref: "alcoholThresholdForm",
                            attrs: { layout: "horizontal" }
                          },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                ref: "alcoholThreshold",
                                attrs: {
                                  label: "アルコール濃度閾値",
                                  prop: "alcoholThreshold"
                                }
                              },
                              [
                                _c("a-input-number", {
                                  attrs: { min: 0, max: 100 },
                                  model: {
                                    value: _vm.alcoholThreshold,
                                    callback: function($$v) {
                                      _vm.alcoholThreshold = $$v
                                    },
                                    expression: "alcoholThreshold"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            !_vm.alcoholThresholdLoading
              ? _c(
                  "p",
                  {
                    staticClass: "text-center",
                    class: _vm.$style.buttonContainer
                  },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        attrs: {
                          loading: _vm.alcoholThresholdButtonLoading,
                          type: "primary"
                        },
                        on: { click: _vm.onSubmitalcoholThreshold }
                      },
                      [_vm._v("\n            更新\n          ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { class: _vm.$style.cardContainer }, [
            _c("section", { staticClass: "card" }, [
              _vm._m(4),
              _c("div", { staticClass: "card-body" }, [
                _vm.alcoholMeasurementHideLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.alcoholMeasurementHideLoading
                  ? _c(
                      "div",
                      { class: _vm.$style.notificationMailSettings },
                      [
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: _vm.alcoholMeasurementHide,
                              callback: function($$v) {
                                _vm.alcoholMeasurementHide = $$v
                              },
                              expression: "alcoholMeasurementHide"
                            }
                          },
                          [
                            _c("a-radio", { attrs: { value: 0 } }, [
                              _vm._v("表示する")
                            ]),
                            _c("a-radio", { attrs: { value: 1 } }, [
                              _vm._v("表示しない")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            !_vm.alcoholMeasurementHideLoading
              ? _c(
                  "p",
                  {
                    staticClass: "text-center",
                    class: _vm.$style.buttonContainer
                  },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        attrs: {
                          loading: _vm.alcoholMeasurementHideButtonLoading,
                          type: "primary"
                        },
                        on: { click: _vm.onSubmitalcoholMeasurementHide }
                      },
                      [_vm._v("\n            更新\n          ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { class: _vm.$style.cardContainer }, [
            _c("section", { staticClass: "card" }, [
              _vm._m(5),
              _c("div", { staticClass: "card-body" }, [
                _vm.freeItemAlcoholCheckLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.freeItemAlcoholCheckLoading
                  ? _c(
                      "div",
                      [
                        _c("a-list", {
                          attrs: {
                            "item-layout": "horizontal",
                            "data-source": _vm.freeItemAlcoholCheckList,
                            pagination: false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "renderItem",
                                fn: function(item) {
                                  return _c(
                                    "a-list-item",
                                    {},
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { slot: "actions" },
                                          slot: "actions"
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateFreeItemAlcoholCheck(
                                                    item.id,
                                                    item.name,
                                                    item.show_timing,
                                                    item.edit_self_flag,
                                                    item.priority,
                                                    item.choice,
                                                    item.required_item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("編集")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("a-list-item-meta", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "list-name",
                                            attrs: { slot: "title" },
                                            slot: "title"
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(_vm._s(item.name) + " "),
                                              _c("small", [
                                                _vm._v(
                                                  "[自身での編集: " +
                                                    _vm._s(
                                                      item.edit_self_flag === 0
                                                        ? "不許可"
                                                        : "許可"
                                                    ) +
                                                    " / 優先度: " +
                                                    _vm._s(item.priority) +
                                                    " / 回答: " +
                                                    _vm._s(
                                                      item.required_item === 0
                                                        ? "任意"
                                                        : "必須"
                                                    ) +
                                                    " / 表示: " +
                                                    _vm._s(
                                                      _vm.showTimingChoice[
                                                        item.show_timing
                                                      ]
                                                    ) +
                                                    "]"
                                                )
                                              ])
                                            ])
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            2359061721
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _c(
              "p",
              { staticClass: "text-center", class: _vm.$style.buttonContainer },
              [
                _c(
                  "a-button",
                  {
                    class: _vm.$style.filledBtn,
                    attrs: {
                      loading: _vm.freeItemAlcoholCheckButtonLoading,
                      type: "primary"
                    },
                    on: { click: _vm.onMakeFreeItemAlcoholCheck }
                  },
                  [_vm._v("\n            追加\n          ")]
                )
              ],
              1
            )
          ]),
          _vm.plus
            ? _c("div", { class: _vm.$style.cardContainer }, [
                _c("section", { staticClass: "card" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "card-body" }, [
                    _vm.inspectionItemLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [_c("a-spin", { attrs: { tip: "Loading..." } })],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("a-list", {
                              attrs: {
                                "item-layout": "horizontal",
                                "data-source": _vm.inspectionItemList,
                                pagination: false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "renderItem",
                                    fn: function(item) {
                                      return _c(
                                        "a-list-item",
                                        {},
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { slot: "actions" },
                                              slot: "actions"
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onUpdateInspectionItem(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("編集")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("a-list-item-meta", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "list-name",
                                                attrs: { slot: "title" },
                                                slot: "title"
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.name) +
                                                      "\n                        "
                                                  ),
                                                  _c(
                                                    "small",
                                                    [
                                                      _vm._v(
                                                        "\n                          [カテゴリ: " +
                                                          _vm._s(
                                                            item.category
                                                          ) +
                                                          "\n                            / 回答: " +
                                                          _vm._s(
                                                            item.required_item ===
                                                              1
                                                              ? "必須"
                                                              : "任意"
                                                          ) +
                                                          "\n                            / 優先度: " +
                                                          _vm._s(
                                                            item.priority
                                                          ) +
                                                          "\n                            "
                                                      ),
                                                      item.locked_item === 1
                                                        ? [
                                                            _vm._v(
                                                              "/ デフォルト項目]"
                                                            )
                                                          ]
                                                        : [_vm._v("]")]
                                                    ],
                                                    2
                                                  )
                                                ])
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ],
                                null,
                                false,
                                3676072165
                              )
                            })
                          ],
                          1
                        )
                  ])
                ]),
                _c(
                  "p",
                  {
                    staticClass: "text-center",
                    class: _vm.$style.buttonContainer
                  },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        attrs: {
                          loading: _vm.inspectionItemButtonLoading,
                          type: "primary"
                        },
                        on: { click: _vm.onMakeInspectionItem }
                      },
                      [_vm._v("\n            追加\n          ")]
                    ),
                    !_vm.inspectionItemLoading &&
                    _vm.inspectionItemList.length === 0
                      ? _c(
                          "a-button",
                          {
                            staticClass: "ml-4",
                            class: _vm.$style.filledBtn,
                            attrs: {
                              loading: _vm.inspectionItemButtonLoading,
                              type: "primary"
                            },
                            on: { click: _vm.onMakeDefaultInspectionItem }
                          },
                          [
                            _vm._v(
                              "\n            デフォルトの項目を作る\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { class: _vm.$style.cardContainer }, [
            _c("section", { staticClass: "card" }, [
              _vm._m(7),
              _c("div", { staticClass: "card-body" }, [
                _vm.freeItemUserLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.freeItemUserLoading
                  ? _c(
                      "div",
                      [
                        _c("a-list", {
                          attrs: {
                            "item-layout": "horizontal",
                            "data-source": _vm.freeItemUserList,
                            pagination: false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "renderItem",
                                fn: function(item) {
                                  return _c(
                                    "a-list-item",
                                    {},
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { slot: "actions" },
                                          slot: "actions"
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateFreeItemUser(
                                                    item.id,
                                                    item.name,
                                                    item.hide_drive_data_list,
                                                    item.priority
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("編集")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("a-list-item-meta", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "list-name",
                                            attrs: { slot: "title" },
                                            slot: "title"
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(_vm._s(item.name) + " "),
                                              _c("small", [
                                                _vm._v(
                                                  "[運行: " +
                                                    _vm._s(
                                                      item.hide_drive_data_list
                                                        ? "非表示"
                                                        : "表示"
                                                    ) +
                                                    " | 優先度: " +
                                                    _vm._s(item.priority) +
                                                    "]"
                                                )
                                              ])
                                            ])
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3230794147
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _c(
              "p",
              { staticClass: "text-center", class: _vm.$style.buttonContainer },
              [
                _c(
                  "a-button",
                  {
                    class: _vm.$style.filledBtn,
                    attrs: {
                      loading: _vm.freeItemUserButtonLoading,
                      type: "primary"
                    },
                    on: { click: _vm.onMakeFreeItemUser }
                  },
                  [_vm._v("\n            追加\n          ")]
                )
              ],
              1
            )
          ]),
          _c("div", { class: _vm.$style.cardContainer }, [
            _c("section", { staticClass: "card" }, [
              _vm._m(8),
              _c("div", { staticClass: "card-body" }, [
                _vm.drivingPurposeLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.drivingPurposeLoading
                  ? _c(
                      "div",
                      [
                        _c("a-list", {
                          attrs: {
                            "item-layout": "horizontal",
                            "data-source": _vm.drivingPurposeList,
                            pagination: false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "renderItem",
                                fn: function(item) {
                                  return _c(
                                    "a-list-item",
                                    {},
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { slot: "actions" },
                                          slot: "actions"
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateDrivingPurpose(
                                                    item.id,
                                                    item.name
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("編集")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("a-list-item-meta", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "list-name",
                                            attrs: { slot: "title" },
                                            slot: "title"
                                          },
                                          [_c("p", [_vm._v(_vm._s(item.name))])]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            699764961
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _c(
              "p",
              { staticClass: "text-center", class: _vm.$style.buttonContainer },
              [
                _c(
                  "a-button",
                  {
                    class: _vm.$style.filledBtn,
                    attrs: {
                      loading: _vm.drivingPurposeButtonLoading,
                      type: "primary"
                    },
                    on: { click: _vm.onMakeDrivingPurpose }
                  },
                  [_vm._v("\n            追加\n          ")]
                )
              ],
              1
            )
          ]),
          _vm.useAnalyze && !_vm.isFleet
            ? _c("div", { class: _vm.$style.cardContainer }, [
                _vm.useAnalyze
                  ? _c("section", { staticClass: "card" }, [
                      _vm._m(9),
                      _c("div", { staticClass: "card-body" }, [
                        _vm.noticeEmailAddressLoading
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [_c("a-spin", { attrs: { tip: "Loading..." } })],
                              1
                            )
                          : _vm._e(),
                        !_vm.noticeEmailAddressLoading
                          ? _c(
                              "div",
                              [
                                _c("a-list", {
                                  attrs: {
                                    "item-layout": "horizontal",
                                    "data-source": _vm.noticeEmailAddressList,
                                    pagination: false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "renderItem",
                                        fn: function(item) {
                                          return _c(
                                            "a-list-item",
                                            {},
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { slot: "actions" },
                                                  slot: "actions"
                                                },
                                                [
                                                  _c(
                                                    "a-button",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateNoticeEmailAddress(
                                                            item.id,
                                                            item.email
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("編集")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("a-list-item-meta", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "list-name",
                                                    attrs: { slot: "title" },
                                                    slot: "title"
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(_vm._s(item.email))
                                                    ])
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1891629270
                                  )
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                !_vm.noticeEmailAddressLoading
                  ? _c(
                      "p",
                      {
                        staticClass: "text-center",
                        class: _vm.$style.buttonContainer
                      },
                      [
                        _c(
                          "a-button",
                          {
                            class: _vm.$style.filledBtn,
                            attrs: {
                              loading: _vm.excludeViolationButtonLoading,
                              type: "primary"
                            },
                            on: { click: _vm.onMakeNoticeEmailAddress }
                          },
                          [_vm._v("\n            追加\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.useAnalyze && !_vm.isFleet
            ? _c("div", { class: _vm.$style.cardContainer }, [
                _c("section", { staticClass: "card" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "card-body" }, [
                    _vm.noticeEmailSettingLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [_c("a-spin", { attrs: { tip: "Loading..." } })],
                          1
                        )
                      : _vm._e(),
                    !_vm.noticeEmailSettingLoading
                      ? _c(
                          "div",
                          { class: _vm.$style.notificationMailSettings },
                          [
                            _c(
                              "a-form-model",
                              {
                                ref: "noticeEmailSettingRuleForm",
                                attrs: {
                                  model: _vm.noticeEmailSettingForm,
                                  rules: _vm.noticeEmailSettingRules,
                                  hideRequiredMark: true,
                                  layout: "horizontal"
                                }
                              },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    ref: "emailSendTime",
                                    attrs: {
                                      label: "送信時刻",
                                      prop: "emailSendTime"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: _vm.$style.requiredMark },
                                      [_vm._v("必須")]
                                    ),
                                    _c("a-time-picker", {
                                      attrs: {
                                        format: "HH:mm",
                                        "minute-step": 15
                                      },
                                      on: { change: _vm.onChangeEmailSendTime },
                                      model: {
                                        value: _vm.emailSendTimeMoment,
                                        callback: function($$v) {
                                          _vm.emailSendTimeMoment = $$v
                                        },
                                        expression: "emailSendTimeMoment"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    ref: "numberOfRanking",
                                    attrs: {
                                      label: "ランキング件数",
                                      prop: "numberOfRanking"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: _vm.$style.requiredMark },
                                      [_vm._v("必須")]
                                    ),
                                    _c("a-input-number", {
                                      attrs: { min: 0, max: 10000 },
                                      model: {
                                        value:
                                          _vm.noticeEmailSettingForm
                                            .numberOfRanking,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.noticeEmailSettingForm,
                                            "numberOfRanking",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "noticeEmailSettingForm.numberOfRanking"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "12px",
                                          color: "#1F2437",
                                          "font-size": "14px",
                                          "line-height": "33px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "※すべてを出力する場合は0を入れてください。"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("h2", { class: _vm.$style.midTitle }, [
                                  _vm._v("メールに含めるランキング")
                                ]),
                                _c(
                                  "div",
                                  { class: _vm.$style.mailRankingSettings },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        ref: "emailContentComplianceRate",
                                        attrs: {
                                          label: "遵守率",
                                          prop: "emailContentComplianceRate",
                                          layout: "horizontal",
                                          "label-col": { span: 3 },
                                          "wrapper-col": { span: 21 }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-checkbox-group",
                                          {
                                            attrs: {
                                              "default-value":
                                                _vm.emailContentComplianceRate
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeEmailContentComplianceRate
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style
                                                      .rankingItemContainer
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.$style
                                                          .rankingItemTitle
                                                    },
                                                    [_vm._v("全社ランキング")]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ベスト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "0"
                                                            }
                                                          },
                                                          [_vm._v("支店")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "1"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "2"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ワースト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "3"
                                                            }
                                                          },
                                                          [_vm._v("支店")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "4"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "5"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style
                                                      .rankingItemContainer
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.$style
                                                          .rankingItemTitle
                                                    },
                                                    [_vm._v("支店ランキング")]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ベスト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "6"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "7"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ワースト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "8"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "9"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        ref: "emailContentTimeRate",
                                        attrs: {
                                          label: "違反数 / 運転時間",
                                          prop: "emailContentTimeRate",
                                          layout: "horizontal",
                                          "label-col": { span: 3 },
                                          "wrapper-col": { span: 21 }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-checkbox-group",
                                          {
                                            attrs: {
                                              "default-value":
                                                _vm.emailContentTimeRate
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeEmailContentTimeRate
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style
                                                      .rankingItemContainer
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.$style
                                                          .rankingItemTitle
                                                    },
                                                    [_vm._v("全社ランキング")]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ベスト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "0"
                                                            }
                                                          },
                                                          [_vm._v("支店")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "1"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "2"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ワースト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "3"
                                                            }
                                                          },
                                                          [_vm._v("支店")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "4"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "5"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style
                                                      .rankingItemContainer
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.$style
                                                          .rankingItemTitle
                                                    },
                                                    [_vm._v("支店ランキング")]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ベスト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "6"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "7"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ワースト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "8"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "9"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        ref: "emailContentDistanceRate",
                                        attrs: {
                                          label: "違反数 / 運転距離",
                                          prop: "emailContentDistanceRate",
                                          layout: "horizontal",
                                          "label-col": { span: 3 },
                                          "wrapper-col": { span: 21 }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-checkbox-group",
                                          {
                                            attrs: {
                                              "default-value":
                                                _vm.emailContentDistanceRate
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeEmailContentDistanceRate
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style
                                                      .rankingItemContainer
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.$style
                                                          .rankingItemTitle
                                                    },
                                                    [_vm._v("全社ランキング")]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ベスト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "0"
                                                            }
                                                          },
                                                          [_vm._v("支店")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "1"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "2"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ワースト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "3"
                                                            }
                                                          },
                                                          [_vm._v("支店")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "4"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "5"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style
                                                      .rankingItemContainer
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.$style
                                                          .rankingItemTitle
                                                    },
                                                    [_vm._v("支店ランキング")]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ベスト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "6"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "7"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          _vm.$style.itemLabel
                                                      },
                                                      [_vm._v("ワースト")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "8"
                                                            }
                                                          },
                                                          [_vm._v("ユーザー")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "a-checkbox",
                                                          {
                                                            attrs: {
                                                              value: "9"
                                                            }
                                                          },
                                                          [_vm._v("車両")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                _c(
                  "p",
                  {
                    staticClass: "text-center",
                    class: _vm.$style.buttonContainer
                  },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        attrs: {
                          loading: _vm.noticeEmailSettingButtonLoading,
                          type: "primary"
                        },
                        on: { click: _vm.onSubmitnoticeEmailSetting }
                      },
                      [_vm._v("\n            更新\n          ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm.plus
        ? _c("div", { class: _vm.$style.cardContainer }, [
            _c("section", { staticClass: "card" }, [
              _vm._m(11),
              _c("div", { staticClass: "card-body" }, [
                _vm.showCautionDriverLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _c(
                      "div",
                      { class: _vm.$style.notificationMailSettings },
                      [
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: _vm.showCautionDriver,
                              callback: function($$v) {
                                _vm.showCautionDriver = $$v
                              },
                              expression: "showCautionDriver"
                            }
                          },
                          [
                            _c("a-radio", { attrs: { value: 1 } }, [
                              _vm._v("表示する")
                            ]),
                            _c("a-radio", { attrs: { value: 0 } }, [
                              _vm._v("表示しない")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            ]),
            !_vm.showCautionDriverLoading
              ? _c(
                  "p",
                  {
                    staticClass: "text-center",
                    class: _vm.$style.buttonContainer
                  },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        attrs: {
                          loading: _vm.showCautionDriverButtonLoading,
                          type: "primary"
                        },
                        on: { click: _vm.onSubmitShowCautionDriver }
                      },
                      [_vm._v("\n        更新\n      ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.noticeEmailAddressTitle,
            visible: _vm.visibleNoticeEmailAddress
          },
          on: { cancel: _vm.handleCancelNoticeEmailAddress }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "noticeEmailAddressRef",
                  attrs: {
                    rules: _vm.NoticeEmailAddressRules,
                    model: _vm.NoticeEmailAddressForm
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "email",
                      attrs: { label: "メールアドレス", prop: "email" }
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "mail@address.com" },
                        on: {
                          blur: function() {
                            _vm.$refs.email.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.NoticeEmailAddressForm.email,
                          callback: function($$v) {
                            _vm.$set(_vm.NoticeEmailAddressForm, "email", $$v)
                          },
                          expression: "NoticeEmailAddressForm.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.targetNoticeEmailAddressId
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "danger",
                            loading: _vm.confirmNoticeEmailAddressLoading
                          },
                          on: { click: _vm.handleDeleteNoticeEmailAddress }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelNoticeEmailAddress }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.confirmNoticeEmailAddressLoading
                      },
                      on: { click: _vm.handleOkNoticeEmailAddress }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.noticeEmailAddressOkText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.freeItemAlcoholCheckTitle,
            visible: _vm.visibleFreeItemAlcoholCheck
          },
          on: { cancel: _vm.handleCancelFreeItemAlcoholCheck }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "freeItemAlcoholCheckRef",
                  attrs: {
                    rules: _vm.freeItemAlcoholCheckRules,
                    model: _vm.freeItemAlcoholCheckForm
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { ref: "name", attrs: { label: "項目名", prop: "name" } },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.name.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.freeItemAlcoholCheckForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.freeItemAlcoholCheckForm, "name", $$v)
                          },
                          expression: "freeItemAlcoholCheckForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "show_timing",
                      attrs: { label: "表示タイミング", prop: "show_timing" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.freeItemAlcoholCheckForm.show_timing,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.freeItemAlcoholCheckForm,
                                "show_timing",
                                $$v
                              )
                            },
                            expression: "freeItemAlcoholCheckForm.show_timing"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("乗車前後")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("乗車前のみ")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("乗車後のみ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "required_item",
                      attrs: { label: "回答", prop: "required_item" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.freeItemAlcoholCheckForm.required_item,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.freeItemAlcoholCheckForm,
                                "required_item",
                                $$v
                              )
                            },
                            expression: "freeItemAlcoholCheckForm.required_item"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("任意")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("必須")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "edit_self_flag",
                      attrs: {
                        label: "本人による編集の許可",
                        prop: "edit_self_flag"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.freeItemAlcoholCheckForm.edit_self_flag,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.freeItemAlcoholCheckForm,
                                "edit_self_flag",
                                $$v
                              )
                            },
                            expression:
                              "freeItemAlcoholCheckForm.edit_self_flag"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("許可しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("許可する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "priority",
                      attrs: { label: "表示優先度", prop: "priority" }
                    },
                    [
                      _c("a-input-number", {
                        attrs: { min: 0 },
                        model: {
                          value: _vm.freeItemAlcoholCheckForm.priority,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.freeItemAlcoholCheckForm,
                              "priority",
                              $$v
                            )
                          },
                          expression: "freeItemAlcoholCheckForm.priority"
                        }
                      }),
                      _c("br"),
                      _c("p", [_vm._v("※数値が低いほうが左側に表示されます。")])
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "choice",
                      attrs: {
                        label: "選択肢",
                        prop: "choice",
                        extra: "|| で区切ってください。（例: 択1||択2）"
                      }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.choice.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.freeItemAlcoholCheckForm.choice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.freeItemAlcoholCheckForm,
                              "choice",
                              $$v
                            )
                          },
                          expression: "freeItemAlcoholCheckForm.choice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.targetFreeItemAlcoholCheckId
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "danger",
                            loading: _vm.confirmFreeItemAlcoholCheckLoading
                          },
                          on: { click: _vm.handleDeleteFreeItemAlcoholCheck }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelFreeItemAlcoholCheck }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.confirmFreeItemAlcoholCheckLoading
                      },
                      on: { click: _vm.handleOkFreeItemAlcoholCheck }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.modalFreeItemAlcoholCheckText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.inspectionItemTitle,
            visible: _vm.visibleInspectionItem
          },
          on: { cancel: _vm.handleCancelInspectionItem }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "inspectionItemRef",
                  attrs: {
                    rules: _vm.inspectionItemRules,
                    model: _vm.inspectionItemForm
                  }
                },
                [
                  !_vm.inspectionLockedItem
                    ? _c(
                        "a-form-model-item",
                        {
                          ref: "name",
                          attrs: { label: "項目名", prop: "name" }
                        },
                        [
                          _c("a-input", {
                            model: {
                              value: _vm.inspectionItemForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.inspectionItemForm, "name", $$v)
                              },
                              expression: "inspectionItemForm.name"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.inspectionLockedItem
                    ? _c(
                        "a-form-model-item",
                        {
                          ref: "category",
                          attrs: { label: "カテゴリ", prop: "category" }
                        },
                        [
                          _c("a-auto-complete", {
                            attrs: {
                              "data-source": _vm.inspectionItemCategories,
                              "filter-option": _vm.filterOption
                            },
                            model: {
                              value: _vm.inspectionItemForm.category,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.inspectionItemForm,
                                  "category",
                                  $$v
                                )
                              },
                              expression: "inspectionItemForm.category"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.inspectionLockedItem
                    ? _c(
                        "a-form-model-item",
                        {
                          ref: "description",
                          attrs: { label: "説明文", prop: "description" }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { "auto-size": { minRows: 3, maxRows: 5 } },
                            model: {
                              value: _vm.inspectionItemForm.description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.inspectionItemForm,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "inspectionItemForm.description"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "required_item",
                      attrs: { label: "回答", prop: "required_item" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.inspectionItemForm.required_item,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.inspectionItemForm,
                                "required_item",
                                $$v
                              )
                            },
                            expression: "inspectionItemForm.required_item"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("任意")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("必須")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.inspectionLockedItem
                    ? _c(
                        "a-form-model-item",
                        {
                          ref: "priority",
                          attrs: { label: "表示優先度", prop: "priority" }
                        },
                        [
                          _c("a-input-number", {
                            attrs: { min: 0 },
                            model: {
                              value: _vm.inspectionItemForm.priority,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.inspectionItemForm,
                                  "priority",
                                  $$v
                                )
                              },
                              expression: "inspectionItemForm.priority"
                            }
                          }),
                          _c("br"),
                          _c("p", [
                            _vm._v("※数値が低いほうが上側に表示されます。")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.targetInspectionItemId !== 0 && !_vm.inspectionLockedItem
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "danger",
                            loading: _vm.confirmInspectionItemLoading
                          },
                          on: { click: _vm.handleDeleteInspectionItem }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelInspectionItem }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.confirmInspectionItemLoading
                      },
                      on: { click: _vm.handleOkInspectionItem }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.modalInspectionItemText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.freeItemUserTitle,
            visible: _vm.visibleFreeItemUser
          },
          on: { cancel: _vm.handleCancelFreeItemUser }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "freeItemUserRef",
                  attrs: {
                    rules: _vm.FreeItemUserRules,
                    model: _vm.FreeItemUserForm
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { ref: "name", attrs: { label: "項目名", prop: "name" } },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.name.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.FreeItemUserForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.FreeItemUserForm, "name", $$v)
                          },
                          expression: "FreeItemUserForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "hide_drive_data_list",
                      attrs: {
                        label: "運行履歴への表示",
                        prop: "hide_drive_data_list"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.FreeItemUserForm.hide_drive_data_list,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.FreeItemUserForm,
                                "hide_drive_data_list",
                                $$v
                              )
                            },
                            expression: "FreeItemUserForm.hide_drive_data_list"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("表示する")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("表示しない")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "priority",
                      attrs: { label: "表示優先度", prop: "priority" }
                    },
                    [
                      _c("a-input-number", {
                        attrs: { min: 0 },
                        model: {
                          value: _vm.FreeItemUserForm.priority,
                          callback: function($$v) {
                            _vm.$set(_vm.FreeItemUserForm, "priority", $$v)
                          },
                          expression: "FreeItemUserForm.priority"
                        }
                      }),
                      _c("br"),
                      _c("p", [_vm._v("※数値が低いほうが優先されます。")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.targetFreeItemUserId
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "danger",
                            loading: _vm.confirmFreeItemUserLoading
                          },
                          on: { click: _vm.handleDeleteFreeItemUser }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelFreeItemUser }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.confirmFreeItemUserLoading
                      },
                      on: { click: _vm.handleOkFreeItemUser }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.freeItemUserOkText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.drivingPurposeTitle,
            visible: _vm.visibleDrivingPurpose
          },
          on: { cancel: _vm.handleCancelDrivingPurpose }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "drivingPurposeRef",
                  attrs: {
                    rules: _vm.DrivingPurposeRules,
                    model: _vm.drivingPurposeForm
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { ref: "name", attrs: { label: "項目名", prop: "name" } },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.name.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.drivingPurposeForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.drivingPurposeForm, "name", $$v)
                          },
                          expression: "drivingPurposeForm.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.targetDrivingPurposeId
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "danger",
                            loading: _vm.confirmDrivingPurposeLoading
                          },
                          on: { click: _vm.handleDeleteDrivingPurpose }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelDrivingPurpose }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.confirmDrivingPurposeLoading
                      },
                      on: { click: _vm.handleOkDrivingPurpose }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.drivingPurposeOkText) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("除外違反項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("除外アナウンス項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("アナウンス閾値設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("アルコール濃度閾値設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("点呼記録簿にアルコール濃度の表示")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("点呼記録簿の自由項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("運行前点検項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("ユーザーの自由項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("運転日報内の運転目的項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("通知メールアドレス")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("通知メール設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("過労運転管理の表示")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }